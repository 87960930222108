import React, { useEffect } from 'react';
import {
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import EtusivuHero from '../components/EtusivuHero';
import MediaCard from '../components/MediaCard';
import box1 from '../Images/box1.jpeg';
import Contract from '../Images/contract.jpg';
import Ambulance from '../Images/ambulance.jpg';
import Tyoturvallisuus from '../Images/tyoturvallisuus.jpeg';
import kaksnelja from '../Images/kaksnelja.gif';
import heroImage from '../Images/hero.jpeg';
import CTAGrid from '../components/CTAGrid';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    padding: '4em',
    opacity: '0.95',

  },
  section1: {
    marginTop: '3em',
    marginBottom: '3em',
  },
  benefits: {
    padding: '2em',
    backgroundColor: 'white',
    opacity: '0.90',
    marginTop: '0',
  },
  item2: {
    order: 3,
    '@media (max-width: 900px)': {
      order: 2,
    },
  },
  item3: {
    order: 2,
    backgroundColor: 'white',
    padding: '4em',
    opacity: '0.95',
    '@media (max-width: 900px)': {
      order: 3,
    },
  },
});

const Etusivu = () => {
  useEffect(() => {
    ReactGA.initialize("UA-196645655-1");
    ReactGA.pageview('/meista');
  }, []);
  const classes = useStyles();
  
  return (
    <>
      <EtusivuHero title="Oikotiesi vaivattomiin liikennejärjestelyihin" subtitle="Perheyrityksemme hoitaa puolestasi liikennejärjestelyihin liittyvät lupa-asiat ja käytännön järjestelyt , nopeasti ja joustavasti." bgImage={heroImage} />
      <Grid container justify="center" className={classes.benefits}>
        <Grid
          item
          xs={11}
          sm={10}
          md={4}
          lg={3}
          xl={3}
        >
          <MediaCard
            image={box1}
            title="Oma varasto"
            header="Oma varasto"
            text="Liikennejärjestelyihin tarvittavat tarvikkeet toimitamme suoraan omasta varastostamme. Valikoimaamme kuuluvat mm. Työmaa-aidat, portit, opasteet, liikennevalot ja liikennemerkit. "
            buttonHref="/liikennejarjestelyt"
          />
        </Grid>

        <Grid
          item
          xs={11}
          sm={10}
          md={4}
          lg={3}
          xl={3}
        >
          <MediaCard
            image={Contract}
            title="Ei hankalaa byrokratiaa"
            header="Ei hankalaa byrokratiaa"
            text="Suunnittelemme ja toteutamme haastavatkin liikennejärjestelyt. Teemme puolestasi tarvittavat suunnitelmat ja hoidamme lupaprosessit eri viranomaisten kanssa."
            buttonHref="/liikennejarjestelyt"
          />
        </Grid>
        <Grid
          item
          xs={11}
          sm={10}
          md={4}
          lg={3}
          xl={3}
        >
          <MediaCard
            image={kaksnelja}
            title="Palvelemme 24/7"
            header="Palvelemme 24/7"
            text="Perheyrityksenä meitä ohjaavat ihmisläheiset arvot ja työn jälki on meille ylpeyden aihe. Päivystyksemme palveleekin 24/7, joten asiointi kanssamme on vaivatonta ja jouhevaa. "
            buttonHref="/yhteys"
          />
        </Grid>

      </Grid>

      <Grid container justify="center" spacing={0} className={classes.section1}>

        <Grid
          item
          xs={11}
          sm={10}
          md={6}
          lg={5}
          xl={5}
          style={
            {
              backgroundImage: `url(${Ambulance})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: 'auto',
              height: '400px',
              opacity: '1',
            }
            }
        />
        <Grid
          item
          xs={11}
          sm={10}
          md={6}
          lg={5}
          xl={5}
          className={classes.container}
        >

          <Typography variant="h4" component="h1">Varmista hälytysajoneuvojen perillepääsy</Typography>

          <Typography variant="body" component="p">
            Huonosti tehdyt tai kokonaan tekemättä jätetyt liikennejärjestelyt voivat vaarantaa
            hälytysajoneuvojen perillepääsyn.
            Urakoitsijana sinä olet vastuussa asianmukaisista luvista ja ilmoituksista
            viranomaisille. Tekemättä jätetyistä liikennejärjestelyistä johtuen työ voi keskeytyä
            ja rikkomuksesta voidaan myös määrätä sakkoa.
          </Typography>
        </Grid>

        <Grid
          item
          xs={11}
          sm={10}
          md={6}
          lg={5}
          xl={5}
          className={classes.item3}
        >
          <Typography variant="h4" component="h1">Huolehdi työturvallisuudesta</Typography>
          <Typography variant="body" component="p">
            Huolehtimalla liikennejärjestelyistä asianmukaisella tavalla,
            takaat myös työntekijöillesi turvallisen työympäristön.
            Toimitamme myös turvallisen työympäristön kannalta tarpeelliset tuotteet,
            kuten liikennevalot, esteet ja suojat sekä tarvittaessa hoidamme
            myös liikenteenohjauksen.
          </Typography>
        </Grid>

        <Grid
          item
          className={classes.item2}
          xs={11}
          sm={10}
          md={6}
          lg={5}
          xl={5}
          style={
            {
              backgroundImage: `url(${Tyoturvallisuus})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: 'auto',
              height: '400px',
            }
            }
        />

      </Grid>
      <CTAGrid showLisaa={true} />

    </>
  );
};

export default Etusivu;
