/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from './Typography';
import ProductHeroLayout from './ProductHeroLayout';
import backgroundImage from '../Images/liikennejarjestelyt_hero.jpeg';

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function ProductHero({ classes, title, subtitle }) {
  return (
    <ProductHeroLayout
      backgroundClassName={classes.background}
    >
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      <Typography color="inherit" align="center" variant="h2" marked="center" style={{ zIndex: '3' }}>
        {title}
      </Typography>
      <Typography color="inherit" align="center" variant="h5" className={classes.h5} style={{ zIndex: '3', marginTop: '0' }}>
        {subtitle}
      </Typography>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
