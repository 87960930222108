/* eslint-disable max-len */
import { React, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
  Paper,
} from '@material-ui/core';
import CTAGrid from '../components/CTAGrid';
import LiikennejarjestelytHero from '../components/LiikennejarjestelytHero';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    padding: '1.3em',
    opacity: '0.95',
  },
});

const Liikennejarjestelyt = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.initialize("UA-196645655-1");
    ReactGA.pageview('/liikennejarjestelyt');
  }, []);
  const classes = useStyles();

  return (
    <>
      <LiikennejarjestelytHero title="Liikennejärjestelyt vaivattomasti" subtitle="Teemme hankalat paperisodat ja käytännön järjestelyt puolestasi, nopeasti ja joustavasti" />
      <Container maxWidth="lg" classname={classes.container}>
        <Grid container>
          <Grid
            item
          >
            <Paper
              style={
                        {
                          padding: '4em',
                          marginTop: '4em',
                          marginBottom: '4em',
                        }
          }
            >
              <Typography variant="body" component="p">
                Tiesitkö, että kun työskentelet kaupungin kaduilla, tarvitset siihen soveltuvan
                työluvan?
                Monesti työnteko ihmisvilinässä, autojen keskellä tai raitiovaunujen läheisyydessä voi
                aiheuttaa vaaratilanteita ulkopuolisille kuten myös itse työntekijälle.
                Siksi on tärkeää, että työkohteesi on etukäteen hyvin suunniteltu ja siihen
                on hankittu tarvittavat luvat ja suunnitelmat. Luvaton työskentely johtaa työn
                keskeytymiseen ja ylimääräisiin kuluihin.
                Yrityksemme on keskittynyt suunnittelemaan ja toteuttamaan niin pienet kuin
                isotkin liikennejärjestelyt. Oli kyseessä ikkunanpesu tai iso nostotyö,
                suunnittelemme ja toteutamme työn ammattitaidolla.
                Oikein tehdyt liikennejärjestelyt nopeuttavat työsuoritusta, mutta ennen kaikkea takaavat työntekijöille
                työturvan ja muille kadun käyttäjille turvallisen ja sujuvan liikennöinnin.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <CTAGrid />
    </>
  );
};

export default Liikennejarjestelyt;
