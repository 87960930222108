import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Link, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import EmailIcon from '@material-ui/icons/Email';
// import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import Logo from '../Images/varmatie_logo.png';
import circle247 from '../Images/24_7.svg';

const footerData = [
  {
    label: 'Meistä',
    href: '/meista',
  },
  {
    label: 'Liikennejärjestelyt',
    href: '/liikennejarjestelyt',
  },
  {
    label: 'Ota yhteyttä',
    href: '/yhteys',
  },
];

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: '#0f1620',
    paddingTop: '1.5em',
    paddingBottom: '1.5em',
    paddingRight: '100px',
    paddingLeft: '100px',
    '@media (max-width: 1024px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justify="space-evenly" className={classes.footer}>

      <Grid
        style={{ padding: '1em 0' }}
        item
        xs={11}
        sm={10}
        md={4}
        lg={2}
        xl={2}
      >
        <img alt="Varmatie logo" src={Logo} style={{ width: '100px' }} />
      </Grid>
      <Grid
        item
        style={{ color: 'white' }}
        xs={11}
        sm={10}
        md={4}
        lg={2}
        xl={2}
      >
        {
          footerData.map(({ label, href }) => (
            <>
              <Link
                {...{
                  component: RouterLink,
                  to: href,
                  color: 'inherit',
                  style: { textDecoration: 'none' },
                  key: label,
                }}
              >
                {label}
              </Link>
              <br />
            </>
          ))
}

      </Grid>

      <Grid
        item
        style={{ display: 'flex', flexDirection: 'column', padding: '1em 0' }}
        xs={11}
        sm={10}
        md={4}
        lg={2}
        xl={2}
      >
        <a href="https://www.facebook.com/varmatie">
          <FacebookIcon style={{ color: 'white', fontSize: '35' }} />
        </a>
        <a href="https://instagram.com">
          {/* <InstagramIcon style={{ color: 'white', fontSize: '35', paddingTop: '0.1em' }} /> */}
        </a>
      </Grid>

      <Grid
        item
        style={{ color: 'white' }}
        xs={11}
        sm={10}
        md={4}
        lg={2}
        xl={2}
      >
        <Typography>Varmatie Oy</Typography>
        <Typography>Viikatetie 27A 2</Typography>
        <Typography>01640 Vantaa</Typography>
        <a href="tel:040 052 1198">
        <Typography style={{color: 'white'}}>
          040 052 1198
        </Typography>
        </a>
      </Grid>

      <Grid
        item
        style={{ display: 'flex', alignItems: 'center', padding: '1em 0' }}
        xs={9}
        sm={10}
        md={4}
        lg={2}
        xl={2}
      >
        <img style={{ width: '90px' }} alt="" src={circle247} />
        <div style={{ paddingLeft: '1em' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PhoneEnabledIcon style={{ color: '#e61d15', fontSize: '35' }} />
            <a href="tel:040 052 1198"><Typography style={{ display: 'inline', color: '#fff200', paddingLeft: '0.3em' }}>
              040 052 1198
            </Typography></a>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <EmailIcon style={{ color: '#e61d15', fontSize: '35' }} />
            <a href="mailto:info@varmatie.com"><Typography style={{ display: 'inline', color: '#fff200', paddingLeft: '0.3em' }}>info@varmatie.com</Typography></a>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Footer;
