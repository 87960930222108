import { React, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Paper,
  Grid,
  Container,
  makeStyles,
} from '@material-ui/core';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import EmailIcon from '@material-ui/icons/Email';
import ContactForm from '../components/ContactForm';
import EtusivuHero from '../components/EtusivuHero';
import ReactGA from 'react-ga';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    padding: '2em 1em',
    opacity: '0.95',
    justifyContent: 'center',

  },
  contactDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contactTypography: {
    display: 'inline',
    color: '#0f1620',
    paddingLeft: '0.3em',
    fontSize: '1rem',
    fontWeight: '700',
  },
});
const Tarjouspyynto = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.initialize("UA-196645655-1");
    ReactGA.pageview('/yhteys');
  }, []);

  const classes = useStyles();

  return (
    <>
      <EtusivuHero title="Ota yhteyttä" />
      <Container maxWidth="lg" style={{ padding: '2em' }}>

        <Grid container className={classes.container}>
          <Grid item xl={6} style={{ margin: '1em 1em' }}>
            <Paper variant="outlined" style={{ padding: '2em' }}>

              <Typography variant="h5">Varmatie Oy</Typography>
              <Typography style={{ fontSize: '1rem', fontWeight: '700' }}>
                Viikatetie 27A 2
                <br />
                01640 Vantaa
              </Typography>
              <div style={{ paddingLeft: '1em', paddingTop: '1em' }}>
                <Typography variant="h5">Myynti ja katselmukset</Typography>
                <Typography className={classes.contactTypography}>Joni Hirvonen</Typography>
                <div className={classes.contactDiv}>
                  <PhoneEnabledIcon style={{ color: '#0f1620', fontSize: '35' }} />
                  <a href="tel:040 052 1198"><Typography className={classes.contactTypography}>
                    040 052 1198
                  </Typography></a>
                </div>
                <div className={classes.contactDiv}>
                  <EmailIcon style={{ color: '#0f1620', fontSize: '35' }} />
                  <a href="mailto:info@varmatie.com"><Typography className={classes.contactTypography}>
                    info@varmatie.com
                  </Typography></a>
                </div>
              </div>
            </Paper>
          </Grid>
          <Paper variant="outlined" style={{ padding: '2em' }}>
            <ContactForm />

          </Paper>
        </Grid>

      </Container>
    </>
  );
};

export default Tarjouspyynto;
