import React from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import
{
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core';
import Header from './views/Header';
import Footer from './views/Footer';
import Etusivu from './views/Etusivu';
import Liikennejarjestelyt from './views/Liikennejarjestelyt';
import Yhteys from './views/Yhteys';
import backgroundImage from './Images/triangles_background.svg';
import '@fontsource/roboto';
import '@fontsource/open-sans';


let theme3 = createMuiTheme();
theme3 = responsiveFontSizes(theme3);


function App() {
 
  return (
    
    <div className="App" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <ThemeProvider theme={theme3}>
        <Header />
        <Switch>
          <Route path="/meista">
            <Etusivu />
          </Route>

          <Route path="/liikennejarjestelyt">
            <Liikennejarjestelyt />
          </Route>

          <Route path="/yhteys">
            <Yhteys />
          </Route>

          <Route path="/">
            <Etusivu />
          </Route>

        </Switch>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
