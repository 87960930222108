import {React, useState} from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

const validationSchema = yup.object({
  name: yup
    .string('Syötä nimesi')
    .required('Nimi vaaditaan'),
  phone: yup
    .number('Syötä puhelinnumerosi')
    .typeError('Syötä vain numeroita')
    .required('Puhelinnumero vaaditaan'),
  email: yup
    .string('Syötä sähköposti')
    .email('Syötä kelvollinen sähköpostiosoite')
    .required('Sähköposti vaaditaan'),
  subject: yup
    .string('Kirjoita aihe')
    .required('Aihe vaaditaan'),
    text: yup
    .string('Kirjoita viesti')
    .required('Viesti vaaditaan'),
});

const ContactForm = () => {
  const [serverState, setServerState] = useState();

  const handleServerResponse = (ok, msg) => {
    setServerState({ok, msg});
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      subject: '',
      text:'',
    },
    validationSchema,
    onSubmit: (values, actions) => {
      axios({
        method: "POST",
        url: "https://varmatiebackend.herokuapp.com/send",
        data: values
      })
        .then(response => {
          actions.setSubmitting(false);
          actions.resetForm();
          handleServerResponse(true, "Kiitos yhteydenotosta!");
        })
        .catch(error => {
          actions.setSubmitting(false);
          // handleServerResponse(false, error.response.data.error);
        });
      // alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        id="name"
        name="name"
        label="Nimi"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.email && formik.errors.name}
      />
      <TextField
        fullWidth
        id="phone"
        name="phone"
        label="Puhelin"
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
      />
      <TextField
        fullWidth
        id="email"
        name="email"
        label="Sähköposti"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <TextField
        fullWidth
        id="subject"
        name="subject"
        label="Aihe"
        type="subject"
        value={formik.values.subject}
        onChange={formik.handleChange}
        error={formik.touched.subject && Boolean(formik.errors.subject)}
        helperText={formik.touched.subject && formik.errors.subject}
      />
      <TextField
        style={{ margin: '1em 0', width: '100%' }}
        id="text"
        name="text"
        label="Viesti"
        multiline
        rows={4}
        variant="outlined"
        value={formik.values.text}
        onChange={formik.handleChange}
        error={formik.touched.text && Boolean(formik.errors.text)}
        helperText={formik.touched.text && formik.errors.text}
      />
      <Button color="primary" variant="contained" fullWidth type="submit">
        Lähetä
      </Button>
      {serverState && (
                  <p className={!serverState.ok ? "errorMsg" : ""}>
                    {serverState.msg}
                  </p>
                )}
    </form>
  );
};

export default ContactForm;
