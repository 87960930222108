/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    margin: '0.3em',
    minHeight: '450px',
  },
  media: {
    height: 200,
  },
  button: {
    backgroundColor: '#0f1620',
    color: 'white',
  },
});

const MediaCard = ({
  image,
  title,
  header,
  text,
  buttonHref,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Card variant="outlined" className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={image}
          title={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {header}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Button size="large" className={classes.button} variant="contained" onClick={() => history.push(`${buttonHref}`)}>
          Lue lisää
        </Button>
      </CardActions>
    </Card>
  );
};

export default MediaCard;
