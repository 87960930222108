import React from 'react';
import {
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Bump from '../Images/bump.jpg';

const CTAGrid = ({showLisaa}) => {
  const history = useHistory();
  return (
    <Grid
      container
      justify="center"
      style={
       {
         backgroundColor: 'white',
         opacity: '0.90',
         padding: '3em 0',
       }
        }
    >
      <Grid
        item
        xs={11}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        style={
          {
            backgroundImage: `url(${Bump})`,
            backgroundSize: 'cover',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat',
            width: 'auto',
            height: '400px',
            border: '1px solid',
          }
          }
      />

      <Grid
        item
        xs={11}
        sm={10}
        md={6}
        lg={5}
        xl={5}
        style={
      {
        backgroundColor: 'white',
        padding: '4em',
        opacity: '0.90',
        border: '1px solid',
      }
}
      >
        <Typography variant="h4" component="h1">Jätä liikennejärjestelyt meidän huoleksemme!</Typography>
        <Typography variant="body" component="p">
          Teemme liikennejärjestelyt puolestasi avaimet käteen-periaatteella.
          Temme puolestasi tarvittavat suunnitelmat ja lupahakemukset, sekä ilmoitukset
          eri viranomaisille. Työmaatarvikkeet toimitamme myös omasta varastostamme.
          Tarvittaessa hoidamme myös lentoliikenneluvat.
        </Typography>

        {
          showLisaa &&
          <Button
          size="large"
          variant="contained"
          onClick={() => history.push('/liikennejarjestelyt')}
          style={
                {
                  backgroundColor: '#0f1620',
                  color: '#fff200',
                  marginTop: '2em',
                }
              }
        >
          Lue lisää
        </Button>
        }
        <Button
          id="tarjouspyynto"
          size="large"
          variant="contained"
          onClick={() => history.push('/yhteys')}
          style={
                {
                  backgroundColor: '#0f1620',
                  color: '#e61d15',
                  marginTop: '2em',
                  marginLeft: '0.3em',
                }
              }
        >
          Pyydä tarjous
        </Button>
      </Grid>
    </Grid>

  );
};

export default CTAGrid;
